<template>
  <div class="news" @click="handleRouter('news')">
    <div class="reading" v-if="total > 0">
      <span>{{ total }}</span>
    </div>
  </div>
  <div class="person" @click="showInfo"></div>
  <div class="person-box" v-if="isShow">
    <div class="person-info">
      <div class="head">
        <img src="../assets/img/用户-默认.png" alt="" />
      </div>
      <div class="intro-info">
        <h2 v-if="user.userInfo">{{ user.userInfo.truename }}</h2>
        <p v-if="user.org">{{ user.org }}</p>
        <p v-if="user.userInfo">{{ user.userInfo.cstnetId }}</p>
        <div class="logout" @click="logout">退出</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
let isShow = ref(false);
let axios = inject("axios");
let total = ref(0);
//切换用户信息展示
const showInfo = () => {
  showBox();
  isShow.value = true;
};
let router = useRouter();
const logout = () => {
  // localStorage.clear();
  // router.push("/login");
  window.location.href ="https://passport.escience.cn/logout?WebServerURL=http://dc.casdc.cn";
};

//获取未读消息
const getNoRead = () => {
  let url = `/message/getAuditLogsUnRead`;
  axios
    .get(url)
    .then((res) => {
      if (res.data.code == 200) {
        total.value = res.data.data;
      } else {
        total.value = 0;
      }
    })
    .catch((error) => {
      total.value = 0;
    });
};

//点击空白关闭用户信息
const showBox = () => {
  let body = document.body;
  let box = document.createElement("div");
  box.className = "transparent";
  body.appendChild(box);
  box.addEventListener("click", () => {
    isShow.value = false;
    body.removeChild(box);
  });
};

let user = ref({});
onMounted(() => {
  if (localStorage.getItem("User")) {
    let info = JSON.parse(localStorage.getItem("User"));
    user.value = info;
  }
  //重新查询未读消息总数
  bus.on("noticeCount", () => {
    getNoRead();
  });
  getNoRead();
});

//调往消息中心
const handleRouter = (name) => {
  router.push({ name });
};
onBeforeUnmount(() => {
  bus.all.delete("noticeCount");
});
</script>

<style lang="scss" scoped>
.news,
.person {
  width: 20px;
  height: 18px;
  margin-left: 20px;
}
.news {
  position: relative;
  .reading {
    position: absolute;
    background: #f30707;
    width: 16px;
    height: 16px;
    top: -8px;
    right: -8px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      transform: scale(0.6);
      font-size: 12px;
      display: inline-block;
    }
  }
}
.news {
  background: url("../assets/img/消息-默认.png") no-repeat;
  background-size: cover;
}
.news:hover {
  background: url("../assets/img/消息-选中.png") no-repeat;
  background-size: cover;
  cursor: pointer;
}
.person {
  background: url("../assets/img/用户-默认.png") no-repeat;
  background-size: cover;
}
.person:hover {
  background: url("../assets/img/用户-选中.png") no-repeat;
  background-size: cover;
  cursor: pointer;
}
.person-box {
  position: fixed;
  top: 70px;
  right: 45px;
  z-index: 1000;
  box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
  background: #fff;
  border-radius: 6px;
  // width: 230px;
  .person-info {
    position: relative;
    width: 100%;
    padding: 30px;
    .head {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      height: 50px;
      width: 44px;
      border-radius: 0 0 50% 50%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      background-color: #eeeeee;
      img {
        display: block;
        width: 20px;
        height: 18px;
      }
    }
    .intro-info {
      text-align: center;
      margin-top: 40px;
      h2 {
        color: #111111;
        font-size: 18px;
        margin-bottom: 5px;
      }
      p {
        color: #888888;
        line-height: 30px;
        font-size: 14px;
      }
      .logout {
        color: #6478b9;
        text-decoration: underline;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
