<template>
  <div class="empty">
    <div class="logo">
      <img src="../../../assets/img/UniversalBlank.png" alt="" />
      <p>空</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .logo {
    img {
      display: block;
      width: 120px;
      height: 120px;
    }
    p {
      font-family: PingFangSC-Regular;
      font-size: 36px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: #555555;
      text-align: center;
      margin-top: 52px;
    }
  }
}
</style>