<!-- 完成或未完成列表表头 -->
<template>
  <div class="header">
    <h2>分类配置</h2>
    <ul>
     
    </ul>
    <div class="button">
      <CommonHeader/>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits,inject ,onMounted } from "vue";
import { useRouter } from "vue-router";
import bus from "@/utils/bus.js";
import CommonHeader from "@/components/commonHeader.vue"
let isEmail = ref(false);
let isPerson = ref(false);
let isShow = ref(0); //0代表未完成，1代表已完成



let router = useRouter();
const history = (name) => {
  router.push({ name});
};
let unCount = ref(0);
let count = ref(0);

onMounted(() => {
});
</script>


<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 15px 0;
  height: 70px;
  > h2 {
    font-family: PingFangSC-Medium;
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 33px;
    letter-spacing: 0px;
    color: #111111;
  }
  ul {
    display: flex;
    li {
      cursor: pointer;
      color: #888888;
      font-family: PingFangSC-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 40px;
      height: 40px;
      width: 150px;
      letter-spacing: 0px;
      text-align: center;
      color: #111111;
    }
    li.active {
      background: #f6f6f6;
      border-radius: 10px;
      color: #111111;
      font-weight: bold;
    }
  }
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 20px;
  }
  .btn-add {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}
.custom-btn {
  border: 1px solid #606060;
  color: #606060;
  border-radius: 6px;
  padding: 3px 20px;
  font-size: 14px;
}
</style>