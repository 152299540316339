<template>
  <div class="content-wrap">
    <Header />
    <div class="search-box">
      <div class="search">
        <ul>
          <li>
            <el-cascader
              :props="props"
              :options="dataTypeList"
              v-model="dataType"
              filterable
              class="width-400"
              placeholder="请选择数据分类"
            />
          </li>
          <li>
            <div
              class="btn search-btn flex-center-center"
              @click="handleSearch"
            >
              <div class="img"></div>
            </div>
            <div
              class="btn reset-btn flex-center-center"
              @click="handleReset"
              v-if="dataType.length > 0"
            >
              <div class="img"></div>
            </div>
          </li>
        </ul>
        <div>
          <div>
            <span
              >共 <span class="bold">{{ total }}</span
              >个</span
            >
          </div>
          <!-- <div class="btn btn-default" @click="DelData">添加机构</div> -->
        </div>
      </div>
    </div>
    <div class="table" v-if="total > 0">
      <Table :columns="columns" :data="tableData" border>
        <template #power="{ row }">
          <span v-for="(item, i) in row.permission" :key="i">
            <span v-if="i != row.permission.length - 1"
              >{{ item.username }} ；</span
            >
            <span v-else>{{ item.username }}</span>
          </span>
        </template>
        <template #action="{ row }">
          <div class="flex">
            <div class="edit action-btn" @click="handleEdit(row)">
              <div class="img"></div>
            </div>
          </div>
        </template>
      </Table>

      <div class="page" v-if="total > 10">
        <Page
          :total="total"
          show-elevator
          show-total
          @on-change="changePage"
          :page-size="pageSize"
          ref="pages"
        />
      </div>
    </div>
    <noData v-if="total == 0" />
    <Footer />
    <classifyModal ref="modal" />
  </div>
</template>
      
   <script setup>
import { ref, inject, onBeforeUnmount, onMounted, watch } from "vue";

import Header from "./components/classifyHeader.vue";
import Footer from "@/components/footer.vue";
import noData from "./components/noData.vue";
import bus from "@/utils/bus.js";
import classifyModal from "./components/classifyModal.vue";

let pages = ref(null);
let modal = ref(null);
let type = ref("");
let columns = [
  {
    title: "一级分类",
    key: "first",
    width: 150,
    resizable: true,
  },
  {
    title: "二级分类",
    key: "second",
    // align: "center",
    width: 150,
    resizable: true,
  },
  {
    title: "三级分类",
    key: "third",
    // align: "center",
    width: 150,
    resizable: true,
  },
  {
    title: "复核权限",
    slot: "power",
    // align: "center",
    // width: 200,
    resizable: true,
  },
  {
    title: "更新人",
    key: "updater",
    width: 200,
    resizable: true,
  },
  {
    title: "更新日期",
    key: "updateDate",
    // align: "center",
    width: 200,
    resizable: true,
  },
  {
    title: "操作",
    slot: "action",
    // align: "center",
    width: 120,
    resizable: true,
  },
];
const props = {
  multiple: false,
  checkStrictly: true,
};
let tableData = ref([]);
let page = ref(1);
let pageSize = ref(10);
let total = ref(0);
let dataTypeList = ref([]);
let dataType = ref([]);
const changePage = (val) => {
  page.value = val;
  getTableData();
};
let axios = inject("axios");
const getTableData = () => {
  let url = `/setting/list`;
  let params = {
    pageOffset: page.value,
    pageSize: pageSize.value,
  };
  if (dataType.value.length > 0) {
    params.first = dataType.value[0];
    params.second = dataType.value[1];
    params.third = dataType.value[2];
  }
  axios.get(url, { params }).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      if (data != null) {
        tableData.value = data.content.map((item) => {
          return {
            ...item,
            third: item.third != null ? item.third : "/",
          };
        });
        total.value = data.count;
      }
    }
  });
};

const handleSearch = () => {
  page.value = 1;
  if (pages.value != null) {
    pages.value.currentPage = 1;
  }

  getTableData();
};
const handleReset = () => {
  dataType.value = "";
  handleSearch();
};

//编辑文件
const handleEdit = (row) => {
  openModal();
  bus.emit("editId", row);
};
//打开modal
const openModal = () => {
  modal.value.modify();
};
//获取数据分类
const getLevelData = () => {
  let url = `/content/index`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      dataTypeList.value = res.data.data;
    }
  });
};

onMounted(() => {
  getLevelData();
  handleSearch();
  bus.on("searchData", () => {
    handleSearch();
  });
});
onBeforeUnmount(() => {
  bus.all.delete("searchData");
});
</script>
      
  <style lang="scss" scoped>
@import "@/views/dataFilling/index.scss";
@import "./index.scss";
.edit .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/编辑-默认.png) no-repeat;
  background-size: cover;
}
.edit:hover .img {
  width: 14px;
  height: 14px;
  background: url(../../assets/img/编辑-选中.png) no-repeat;
  background-size: cover;
}
</style>